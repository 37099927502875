import React, { useEffect } from 'react';
import { Toast } from 'primereact/toast';
import { connect } from 'react-redux';
import AOS from 'aos';
import Layout from '../components/layout';
import Seo from '../components/seo';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import Login from '../components/admin/Login';
import Enquiries from '../components/admin/Enquiries';

const AdminPage = ({ toast, user }) => {
  useEffect(() => {
    AOS.init({ once: true });
    return () => null;
  }, []);
  return (
    <Layout>
      <Seo title="Home" />
      <Toast ref={toast} position="top-right" className="text-left m-w-300" />
      <NavBar />
      {user.id ? <Enquiries /> : <Login />}
      <Footer />
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  toast: state.auth.toast
});

export default connect(mapStateToProps)(AdminPage);
