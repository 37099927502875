import React from 'react';

const SingleEnquiry = () => {
  return (
    <li className="py-3 border-bottom-1 surface-border flex md:align-items-start md:justify-content-between flex-column md:flex-row">
      <div className="flex align-items-start mr-0 lg:mr-5">
        <div>
          <span className="text-900 font-medium block mb-2">Company Name</span>
          <div className="text-700 mb-2">example@email.com</div>
          <a className="text-blue-500 cursor-pointer">
            <i className="pi pi-mobile text-sm mr-2"></i>
            <span>0713425666</span>
          </a>
        </div>
      </div>
      <span className="block text-500 font-medium ml-7 md:ml-5 mt-2 md:mt-0">
        {new Date(Date.now()).toDateString()} <br/> {new Date(Date.now()).toLocaleTimeString()}</span>
    </li>
  );
};

export default SingleEnquiry;
