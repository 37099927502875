import React from 'react';
import SingleEnquiry from './SingleEnquiry';

const Enquiries = () => {
  return (
    <>
      <div className="surface-section px-4 py-5 md:px-6 lg:px-8">
        <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
          <div>
            <div className="font-medium text-3xl text-900">Enquiries</div>
            <div className="flex align-items-center text-700 flex-wrap">
              <div className="mr-5 flex align-items-center mt-3">
                <i className="pi pi-users mr-2"></i>
                <span>332 Enquires Total</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="surface-card shadow-3 border-round p-4 m-2">
        <ul className="list-none p-0 m-0">
          <SingleEnquiry />
          <SingleEnquiry />
          <SingleEnquiry />
        </ul>
      </div>

    </>
  );
};

export default Enquiries;
