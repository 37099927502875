import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { connect } from 'react-redux';
import TextTransparent from '../inputs/TextTransparent';
import { logIn } from '../../state/auth/auth.actions';

const Login = ({ logIn, toast }) => {
  const [inputs, setInputs] = useState({});
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (ev) => {
    try {
      ev.preventDefault();
      setLoading(true);
      await logIn(inputs, toast);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <div className="p-4 d-flex center">
      <div className=" bg-gray-900 p-4 mt-5 mb-5 shadow-2 border-round w-full lg:w-6">
        <div className="text-center mb-5">
          <h1 className="logo-font text-yellow-800">NxPress</h1>
          <div className="text-yellow-800 text-3xl font-medium mb-3">Admin Login</div>
        </div>
        <form className="grid formgrid p-fluid" onSubmit={handleSubmit}>
          <TextTransparent name="email-input" label="Email" left={true} type="email" required={true}
            getValue={(value) => setInputs({ ...inputs, email: value })} />
          <TextTransparent name="password-input" label="Password" left={true} type="password" required={true}
          getValue={(value) => setInputs({ ...inputs, password: value })} />
          <Button label="Sign In" type="submit" icon="pi pi-user" loading={loading}
          className="w-full bg-yellow-500 border-0" />
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  toast: state.auth.toast
});

const mapDispatchToProps = (dispatch) => ({
  logIn: (inputs, toast) => dispatch(logIn(inputs, toast))
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
